import React from "react";
import dynamic from "next/dynamic";
import {Helmet} from 'react-helmet'
import styles from '../styles/Error.module.css';
import Link from "next/link";

const BoxDynamicComponent = dynamic(() => import('../components/box/Box'))

function Error({statusCode}) {
    return (
        <BoxDynamicComponent disableHeader={true} loaded={true} leftBoxClassName={'w-100'}>
            <Helmet title="404 - صفحه یافت نشد"/>
            <div className={styles.container}>
              <img className={styles.image} src="/images/not_found.png" alt="Lost shopper" />
              <h1 className={styles.title}>404 - صفحه یافت نشد</h1>
              <p className={styles.description}>
                ما نتوانستیم صفحه مورد نظر شما را پیدا کنیم. ممکن است حذف شده باشد، نامش تغییر کرده باشد، یا به طور موقت در دسترس نباشد.
              </p>
              <Link href="/">
                <a className={styles.homeLink}>بازگشت به صفحه اصلی</a>
              </Link>
            </div>
        </BoxDynamicComponent>
    )
}

Error.getInitialProps = ({res, err}) => {
    const statusCode = res ? res.statusCode : err ? err.statusCode : 404
    return {statusCode}
}

export default Error